<template>
  <div class="ai-index" :style="{'padding-top': statusBarHeight + 'px'}">
    <van-search
      ref="refSearchInput"
      v-model="searchKeyWord"
      :show-action="true"
      placeholder="有问题尽管问我"
      @input="onInput"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">发送</div>
      </template>
    </van-search>
    <div class="ai-content" :style="{height: `calc(100vh - 80px - ${moreHeight}px)`}">
      <scroller
        ref="my_scroller"
      >
        <div ref="refAiContent">
          <!--显示时间-->
          <div class="show-time" v-if="msgList.length">{{ enterTime | moment("YYYY-MM-DD HH:mm") }}</div>
          <div class="single-message" v-for="item in msgList" :key="item.id">
            <div v-if="!item.self" class="left-box">
              <div v-if="item.loading" class="content-loading">
                <div class="msg-loading-dot"></div>
                <div class="msg-loading-dot"></div>
                <div class="msg-loading-dot"></div>
              </div>
              <div v-else-if="item.msgType === 'text'" class="content-text">{{ item.content }}</div>
              <div v-else-if="item.msgType === 'image'" class="content-image">
                <image
                  :src="item.content"
                  mode="widthFix"
                  class="msg-image"
                  bindtap="previewImage"
                  :data-url="item.content">
                </image>
              </div>
              <div v-if="item.msgType === 'speech'" v-show="!item.loading" class="content-speech">
                <SearchSpeech
                  :ref="'refSearchSpeech-' + item.id"
                  :active="true"
                  :searchId="item.historyId"
                  :chartId="item.id"
                  @result="onLoadFinish('content', item.id)"
                  @getChartData="getHistoryId"/>
              </div>
              <div v-if="item.msgType === 'interaction'" v-show="!item.loading" class="content-speech">
                <search-interaction
                  key="interaction"
                  :keyword="item.content"
                  :active="true"
                  @result="onLoadFinish('content', item.id)" />
              </div>
            </div>
            <div v-if="item.self" class="right-box">
              <div
                v-if="item.msgType === 'text'"
                style="background-color:#def1fc;"
                class="content-text"
                bindtap="clickTextMsg"
                data-text="item.content">
                {{ item.content }}
              </div>
            </div>

            <!-- <div class="stop-btn" v-if="loading" @click="onLoadFinish('content', item.id)">
              <div class="stop-squre"></div>
              <div class="stop-text">停止回答</div>
            </div> -->
          </div>
          <div class="ai-content-bottom"></div>
        </div>
      </scroller>
    </div>
  </div>
</template>
<script>
import SearchSpeech from '@/components/search/SearchSpeech'
import SearchInteraction from '@/components/search/SearchInteraction'
import { mapState } from 'vuex'
export default {
  name: 'AiIndex',
  components: {
    SearchSpeech,
    SearchInteraction
  },
  data () {
    return {
      loading: false,
      msgList: [],
      searchKeyWord: '',
      enterTime: new Date()
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight
    }),
    speechRecognizedKeyword: {
      get () {
        return this.$store.state.speech.speechRecognizedKeyword
      },
      set (val) {
        this.$store.commit('speech/setSpeechRecognizedKeyword', val)
      }
    }
  },
  methods: {
    // loadingType = 'dot'列表项加载结束，开始显示内容;loadingType = 'content'内容显示完毕，可以开始新的搜索;loadingType = 'all', 全部加载完毕
    onLoadFinish (loadingType, id) {
      console.log('******************************loadingType', loadingType)
      if (loadingType === 'dot') {
        this.$set(this.msgList[id], 'loading', false) // 控制是否显示三个点的加载
      } else if (loadingType === 'content') {
        this.loading = false
      } else if (loadingType === 'all') {
        this.$set(this.msgList[id], 'loading', false) // 控制是否显示三个点的加载
        this.loading = false
      }
      this.scrollToBottom()
    },
    getChatSingle (id, keyword) {
      this.$api.ai.chatSingle(keyword).then(res => {
        console.log(res)
      })
    },
    onInput (val) {
      this.searchKeyWord = val
    },
    onSearch () {
      if (this.loading) {
        this.$toast('正在生成中...')
        return
      }
      if (!this.searchKeyWord) return
      this.loading = true
      const id = this.msgList.length
      this.addChartItem(id, true, this.searchKeyWord)
      this.addChartItem(id + 1, false, this.searchKeyWord)
      this.scrollToBottom()
      this.getResponseAi(id + 1, this.searchKeyWord)
    },
    addChartItem (id, self, content) {
      const historyId = (!self && id > 1) ? this.msgList[id - 1].historyId : ''
      this.msgList.push({
        id: id + '',
        self: self,
        msgType: self ? 'text' : '',
        content: content,
        loading: !self, // 不是自己的初始化加载中
        historyId: historyId
      })
    },
    scrollToBottom() {
      const scrollHeight = this.$refs.refAiContent.scrollHeight
      setTimeout(() => {
        this.$refs.my_scroller?.scrollTo(0, scrollHeight, true)
      }, 10)
    },
    async getResponseAi (id, keyword) {
      this.$api.ai.chatStream({ query: keyword }).then(async response => {
        console.log('.............response', response)
        const readableStream = response.body
        if (readableStream) {
          const reader = readableStream.getReader()
          console.log('.............reader', reader)
          let first = true
          while (true && this.loading) {
            const { value, done } = await reader.read()
            if (done) {
              if (this.msgList[id].msgType === 'text') {
                this.onLoadFinish('content', id)
              } else {
                this.onLoadFinish('dot', id)
              }
              break
            }
            const chunkValue = new TextDecoder().decode(value)
            console.log(chunkValue)
            console.log(done)
            this.setAiChart(first, id, chunkValue)
            first = false
          }
          // Stream fully consumed
          reader.releaseLock()
        }
      })
    },
    setAiChart (first, id, chunkValue) {
      let firstItem = first
      console.log(chunkValue)
      const chunkValueArray = chunkValue.split(/"}{"/)
      console.log(chunkValueArray)
      const valueLen = chunkValueArray.length
      chunkValueArray.forEach((item, index) => {
        if (valueLen > 1) { // 返回多个对象时截断了要补回来
          if (index === 0) {
            item += '"}'
          } else if (index === valueLen - 1) {
            item = '{"' + item
          } else {
            item = '{"' + item + '"}'
          }
        }
        console.log(item)
        const chunkValueObj = item ? JSON.parse(item) : {}
        console.log(chunkValueObj)
        if (chunkValueObj.action === 'watch_monitor_video') {
          this.$set(this.msgList[id], 'msgType', 'interaction')
        } else if (chunkValueObj.action === 'analyse_data') {
          this.$set(this.msgList[id], 'msgType', 'speech')
          this.onLoadFinish('dot', id)
          this.$nextTick(() => {
            console.log('nextTick', this.$refs)
            this.$refs['refSearchSpeech-' + id][0].parseKeyword(this.msgList[id].content)
          })
        } else {
          if (firstItem) {
            this.$set(this.msgList, id, { ...this.msgList[id],
              msgType: 'text',
              content: '',
              loading: false // 控制是否显示三个点的加载
            })
          }
          this.$set(this.msgList[id], 'content', this.msgList[id].content + chunkValueObj.response)
          if (index !== 0) { // 如果第一项就是多个返回，则后续项不是第一个
            firstItem = false
          }
        }
      })
    },
    getHistoryId (res) {
      console.log(res)
      if (res.chartId && res.keywordSearchHistoryId) {
        const msgIndex = this.msgList.findIndex(item => item.id === res.chartId)
        if (msgIndex !== -1) {
          this.msgList[msgIndex].historyId = res.keywordSearchHistoryId
        }
      }
    }
  },
  mounted () {
    this.$eventBus.$on('SPEECH_VOICE_KEYWORD', (keyword) => {
      // this.keyword = keyword
      // this.parseKeyword()
      this.searchKeyWord = keyword
      this.onSearch()
    })

    if (this.speechRecognizedKeyword) {
      // this.keyword = this.speechRecognizedKeyword
      // this.parseKeyword()
      // this.speechRecognizedKeyword = ''
      this.searchKeyWord = this.speechRecognizedKeyword
      this.onSearch()
      this.speechRecognizedKeyword = ''
    }
  }
}
</script>
<style lang="less">
  .ai-index {
    background-color: #f8f8f8;
    height: 100vh;
    background-image: url("~@/assets/background.png");
    background-size: 100% 40%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    .van-search {
      margin: 8px 15px;
      background-color: transparent;
      padding-left: 0;
      .van-search__content {
        border-radius: 5px 0 0 5px;
        background-color: #ffffff;
      }
      .van-search__action {
        background-color: #ffffff;
        height: 20px;
        border-radius: 0 5px 5px 0;
        padding: 7px 0;
        div {
          line-height: 20px;
          padding: 0 18px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #319DE2;
          border-left: 1px solid #D4D4D7;
        }
      }
    }
    .ai-content {
      background-color: #f8f8f8;
      font-size: 18px;
      position: relative;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      // ::-webkit-scrollbar { width: 0 !important }
      .pull-to-refresh-layer {
        .text {
          color: #ffffff !important;
        }
        .arrow polygon, .arrow rect {
          fill: #ffffff !important;
        }
      }
      .loading-layer {
        height: 0 !important;
      }
      .show-time {
        font-size: 10px;
        color: #6b6b6b;
        text-align: center;
        padding-top: 20px;
      }
      .left-box {
        padding: 10px;
        text-align: left;
        max-width: 90%;
      }
      .right-box {
        padding: 10px;
        max-width: 90%;
        float: right;
      }
      .single-message:after{
        content: '';
        display: table;
        clear: both;
      }
      .content-text {
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        word-break: break-all;
        width: fit-content;
        text-align: left;
        display: block;
        padding: 4px 8px;
        box-sizing: border-box;
      }
      .content-speech {
        border-radius: 15px;
        overflow: hidden;
        background-color: #fff;
        word-break: break-all;
        width: 100%;
        text-align: left;
      }
      .content-image {
        max-width: 200px;
        margin: 0 20px;
        width: 100%;
        /* background-color: #fff; */
      }
      .content-loading {
        border-radius: 10px;
        overflow: hidden;
        background-color: #fff;
        word-break: break-all;
        width: fit-content;
        text-align: left;
        display: block;
        padding: 4px 8px;
        box-sizing: border-box;
        .msg-loading-dot {
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background-color: #f8f8ff;
          display: inline-block;
          margin: 0 1px;
          animation: gradientColor 1.8s infinite ease-in;
        }
        .msg-loading-dot:nth-child(2){
          animation-delay:0.6s;
        }
        .msg-loading-dot:nth-child(3){
          animation-delay:1.2s;
        }
        @keyframes gradientColor {
          0%,100%{
            background-color: #f8f8ff;
          }
          50%{
            background-color: #5B86E5;
          }
        }
      }
      .ai-content-bottom {
        height: 50px;
      }
    }
  }
</style>
